<template>
	<div class="setting">
		<van-form>
			<van-field name="uploader" required label="头像" :rules="[{ required: true, message: '请选择头像' }]">
				<template #input>
					<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
						v-model="image" />
				</template>
			</van-field>
			<van-field required v-model="formdata.name" name="姓名" label="姓名" placeholder="请填写姓名"
				:rules="[{ required: true, message: '请填写姓名' }]" />
			<van-field required v-model="formdata.phone" name="电话" label="电话" placeholder="请填写电话"
				:rules="[{ required: true, message: '请填写电话' }]" />
			<button class="alterBox" @click="amendMes">修改</button>
		</van-form>
	</div>
</template>

<script>
	import {
		uploadImage
	} from "@/api/common"
	import {
		amendMes
	} from "@/api/user";
	import {
		Toast
	} from "vant";
	import
	http
	from '../../utils/config.js'
	export default {
		name: "Setting",
		data() {
			return {
				formdata: {
					name: '',
					phone: '',
					image: []
				},
				image: [],
				avatar: '',
				imageUrl: http.imageUrl,
			}
		},
		methods: {
			afterDelete(file, detail) {
				switch (detail.name) {
					case 'image':
						this.formdata.image = ''
						this.image.splice(detail.index, 1)
						break
				}
			},
			afterRead(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					// console.log(res)
					if (detail.name === 'image') {
						this.formdata.image = res.data.path
					}
				})
			},
			amendMes() {
				let data = {}
				data.avatar = this.formdata.image
				data.name = this.formdata.name
				data.tel = this.formdata.phone
				data.id = this.$route.query.id
				if(!data.avatar){
					Toast.success('请选择头像')
					return
				}
				if(!data.name){
					Toast.success('请填写姓名')
					return
				}
				if(!data.tel){
					Toast.success('请填写电话')
					return
				}
				amendMes(data).then((res) => {
					// console.log(res)
					Toast.success(res.msg)
					this.$router.go(-1)
				})
			}
		},
		mounted() {
			// console.log(this.$route.query.image)
		}
	}
</script>

<style lang="less" scoped>
	.setting {
		width: 100%;

		.alterBox {
			width: 80%;
			height: 40px;
			position: absolute;
			left: calc(50% - 40%);
			margin-top: 25px;
			color: #fff;
			background-color: #1989fa;
			border: 1px solid #1989fa;
			border-radius: 20px;
		}
	}
</style>
